// Forms
//––––––––––––––––––––––––––––––––––––––––––––––––––

.form-group {
  padding: 0;

  .dropdown .dropdown-toggle {
    height: 60px;
    border: 1px solid var(--light-lines-color);
    border-radius: 2px;
    background-color: transparent;
    box-shadow: 0 2px 4px 0 rgba(216, 216, 216, 0.5);
    font-family: var(--p-font-condensed);
    font-size: 24px;
    font-weight: 300;
    color: var(--light-grey-color);
    letter-spacing: -0.1px;
    line-height: 29px;
    padding: 14px 30px;
    text-align: left;
    text-transform: capitalize;

    &.isEmpty {
      color: var(--input-placeholder-color);
      text-transform: uppercase;
    }
  }

  .input-group {
    input.form-control {
      border-bottom: 1px dashed var(--dark-lines-color);
      padding: 14px 30px;
      background: transparent;
      color: var(--light-grey-color);
      font-family: var(--p-font-condensed);
      font-size: 24px;
      letter-spacing: -0.1px;
      line-height: 29px;

      &::placeholder {
        text-transform: uppercase;
        color: var(--input-placeholder-color);
        font-family: var(--p-font-condensed);
        font-size: 24px;
      }

      &:focus {
        border-color: var(--button-color);
        box-shadow: none !important;
        outline: none !important;
      }
    }
  }
}

/////////////////////
.form-control {
  border-radius: 0;
  border-color: var(--form-border-color);
  font-family: var(--p-font);
  font-weight: 300;
  outline: none !important;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

label, .selected-file {
  color: var(--light-grey-color);
  font-weight: 400;
}

.d-inline-block button {
  width: 100%;
  text-align: left;
  margin-bottom: 8px;
}

.po-form {
  .form-control, .input-group-addon {
    border-color: var(--form-border-color);
    background-color: transparent;
    border-radius: 0;
    transition: all 0.3s ease;
    border-width: 0 0 1px 0 !important;
  }

  .input-group-addon {
    padding: 0 !important;
    border: 0;
  }

  .form-control:focus, .form-control:focus ~ .input-group-addon {
    border-color: rgba(88, 151, 251, 0.5);
    background-color: transparent;
  }
}

.po-form ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: var(--form-border-color);
}

.po-form ::-moz-placeholder { /* Firefox 19+ */
  color: var(--form-border-color);
}

.po-form :-ms-input-placeholder { /* IE 10+ */
  color: var(--form-border-color);
}

.po-form :-moz-placeholder { /* Firefox 18- */
  color: var(--form-border-color);
}

.smallBox {
  textarea {
    font-size: 15px !important;
    border-width: 1px 0 1px 1px !important;
    padding-left: 8px !important;
    resize: none;
  }
}

.pretty {
  input:checked ~ .state.p-brand label:after,
  &.p-toggle .state.p-brand label:after {
    background-color: var(--pretty-color) !important;
  }

  input:checked ~ .state.p-brand .icon,
  input:checked ~ .state.p-brand .svg {
    color: var(--button-color);
    stroke: var(--button-color);
  }

  .p-toggle {
    .state.p-brand .icon,
    .state.p-brand .svg {
      color: var(--pretty-color);
      stroke: var(--pretty-color);
    }
  }

  input:checked ~ .state.p-brand-o label:before,
  &.p-toggle .state.p-brand-o label:before {
    border-color: var(--button-color);
  }

  input:checked ~ .state.p-brand-o label:after,
  &.p-toggle .state.p-brand-o label:after {
    background-color: transparent;
  }

  input:checked ~ .state.p-brand-o .icon,
  input:checked ~ .state.p-brand-o .svg,
  input:checked ~ .state.p-brand-o svg,
  &.p-toggle .state.p-brand-o .icon,
  &.p-toggle .state.p-brand-o .svg,
  &.p-toggle .state.p-brand-o svg {
    color: var(--button-color);
    stroke: var(--button-color);
  }

  &.p-default:not(.p-fill) input:checked ~ .state.p-brand-o label:after {
    background-color: var(--button-color) !important;
  }
}

input, textarea, select {
  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }
}
