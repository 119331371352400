// Tooltip
//––––––––––––––––––––––––––––––––––––––––––––––––––

.toolTip {
  border: 0.5px solid var(--dark-lines-color);
  background-color: var(--tooltip-background-color);
  box-shadow: 0 2px 4px 0 rgba(142, 142, 142, 0.5);
  font-size: 12px;
  letter-spacing: -0.3px;
  line-height: 14px;
  color: var(--light-grey-color);
  position: absolute;
  display: none;
  width: auto;
  height: auto;
  border-radius: 2px 2px 2px 2px;
  padding: 5px;
  text-align: center;
  z-index: 1000;

  &::before {
    position: absolute;
    bottom: -5px;
    left: 45%;
    content: "";
    background: var(--tooltip-background-color);
    height: 10px;
    width: 10px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-bottom: 1px solid rgba(142, 142, 142, 0.5);
    border-right: 1px solid rgba(142, 142, 142, 0.5);
    -webkit-box-shadow: 0 0 52px rgba(142, 142, 142, 0.5);
    box-shadow: 0 0 218px rgba(142, 142, 142, 0.5);
    z-index: -1;
  }
}
