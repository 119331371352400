// Modals
//––––––––––––––––––––––––––––––––––––––––––––––––––

.modal-backdrop.show {
  background-color: rgba(0, 0, 0, 0.32);
}

.modal {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.32);
  box-shadow: 0 2px 20px 0 var(--p-shadow-color);

  &.modal-xs {
    .modal-dialog {
      max-width: 600px !important;
    }

    .modal-content {
      padding: 0;
    }
  }

  &.modal-gt {
    .modal-dialog {
      max-width: 930px !important;
    }
  }

  &.modal-flush {
    .modal-dialog {
      max-width: 600px !important;
    }

    .modal-content {
      border-radius: 0;
      padding: 0;
    }
  }

  .modal-content {
    padding: 0;
    -webkit-box-shadow: 0 2px 20px 0 var(--p-shadow-color);
    -moz-box-shadow: 0 2px 20px 0 var(--p-shadow-color);
    box-shadow: 0 2px 20px 0 var(--p-shadow-color);
    border: none !important;
    background-color: var(--p-color-contrast-bg) !important;

    .modal-header {
      min-height: 76px;
      border-bottom: 1px solid var(--p-color-border-light);
      padding: 20px;

      .modal-title {
        color: var(--p-color-text);
        font-family: var(--p-font-condensed);
        font-size: 24px;
        font-weight: 400;
        letter-spacing: -0.1px;
        line-height: 29px;
        text-transform: uppercase;
        margin: 0 auto;
      }
    }

    .modal-body {
      padding: 50px 85px;
    }

    .form-group {
      margin: 0 auto 1rem auto;
    }
  }
}

@media (min-width: 576px) {
  .modal-xxl {
      --bs-modal-width: 95%;
  }
}

@media (min-width: 1200px) {
  .modal-xxl {
      --bs-modal-width: 90%;
  }
}

@media (min-width: 1600px) {
  .modal-xxl {
      --bs-modal-width: 70%;
  }
}

.modal-70vh .modal-content { 
  height: 70vh !important; 
}
.modal-body-no-padding .modal-body { 
  padding: 0px !important 
}

// This will make Angular Material CDK Overlays appear above Bootstrap modals:
.cdk-overlay-container {
  z-index: 2000;
}