// Buttons
//––––––––––––––––––––––––––––––––––––––––––––––––––

button, .btn {
  cursor: pointer;
}

.btn {
  border-radius: 17.5px;
  height: 30px;
  padding: 0 30px;
  text-transform: uppercase;
  font-family: var(--p-font-condensed);
  font-size: 16px;
  text-align: center;
  line-height: 22px;
  letter-spacing: -0.09px;
  font-weight: 400;
  --bs-btn-disabled-color: #FFF;

  &:disabled, &.disabled {
    cursor: default !important;  // not-allowed
    opacity: var(--bs-btn-disabled-opacity);
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  &.isEmpty {
    color: var(--form-border-color);
  }
}

.btn-primary {
  background-color: var(--button-color);
  color: var(--p-color-white);
  border: none;

  &:hover, &:focus {
    background-color: var(--button-primary-hover-color);
    border: none;
    box-shadow: none;
  }

  &:disabled {
    background-color: var(--dark-lines-color);
    color: var(--p-color-white);
    border: none;
  
    &:hover, &:focus {
      background-color: var(--dark-lines-color);
      border: none;
    }
  }
}

.btn-secondary {
  background-color: var(--dark-lines-color);
  color: var(--p-color-white);
  border: none;

  &:hover, &:focus {
    background-color: var(--button-secondary-hover-color);
    border: none;
  }
}

.btn-tertiary {
  background-color: var(--p-color-white);
  color: var(--dark-lines-color);
  border: 1px solid var(--dark-lines-color);

  &:hover, &:focus {
    background-color: var(--button-secondary-hover-color);
    color: var(--p-color-white);
  }
}

.btn-circle {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  padding: 0.55rem 0;
}

.btn-link {
  padding: 5px 0 !important;
  color: var(--link-color);
  border: none;
  text-decoration: none;
  text-decoration-line: none;
  background-color: transparent;

  &:hover, &:focus {
    padding: 5px 0;
    color: var(--button-primary-hover-color) !important;
    text-decoration: none;
    border: none;
  }

  &.disabled {
    color: var(--link-disabled-color);
    text-decoration: none;
    pointer-events: none;
  }
}

/* btn-transparent */
.btn-trasnparent {
  color: var(--light-grey-color);
  background-color: transparent;
  border-color: transparent;
  border-radius: 0;

  &:hover {
    color: var(--light-grey-color);
    background-color: transparent;
    border-color: var(--link-color);
  }

  &:focus, &.focus {
    color: var(--p-color-white);
  }

  &.disabled, &:disabled {
    background-color: var(--btn-action-disabled-color);
    border-color: var(--btn-action-disabled-color);
  }
}

.btn-trasnparent:active, .btn-trasnparent.active,
.show > .btn-trasnparent.dropdown-toggle {
  color: var(--light-grey-color);
  background-color: transparent;
  background-image: none;
  border-color: var(--link-color);
}

/* btn-gray */
.btn-gray {
  color: var(--btn-color);
  background-color: var(--grey-color);
  border-color: var(--grey-color);

  &:hover {
    color: var(--btn-color);
    background-color: var(--btn-gray-color);
    border-color: var(--btn-gray-color);
  }

  &:focus, &.focus {
    color: var(--p-color-white);
  }

  &.disabled, &:disabled {
    background-color: var(--btn-gray-color);
    border-color: var(--btn-gray-color);
  }
}

.btn-gray:active, .btn-gray.active,
.show > .btn-gray.dropdown-toggle {
  color: var(--btn-color);
  background-color: var(--grey-color);
  background-image: none;
  border-color: var(--grey-color);
}

.btn-outline-gray {
  color: var(--light-grey-color);
  background-color: transparent;
  border-color: var(--form-border-color);
  border-radius: 0;
  font-weight: 300;

  &:hover {
    color: var(--light-grey-color);
    background-color: transparent;
    border-color: var(--form-border-color);
  }

  &:focus, &.focus {
    color: var(--light-grey-color);
  }
}

.btn-outline-gray:active, .btn-outline-gray.active,
.show > .btn-outline-gray.dropdown-toggle {
  color: var(--light-grey-color);
  background-color: transparent;
  background-image: none;
  border-color: var(--form-border-color);
}

.btn-outline {
  color: var(--button-color);
  background-color: transparent;
  border-color: var(--button-color);
  border-radius: 0;
  font-weight: 300;

  &:hover {
    color: var(--button-color);
    background-color: transparent;
    border-color: var(--button-color);
  }

  &:focus, &.focus {
    color: var(--button-color);
  }
}

.btn-outline.disabled, .btn-outline:disabled {
  //background-color: var(--btn-action-disabled-color);
  //border-color: var(--btn-action-disabled-color);
}

.btn-outline:active, .btn-outline.active,
.show > .btn-outline.dropdown-toggle {
  color: var(--button-color);
  background-color: transparent;
  background-image: none;
  border-color: var(--button-color);
}

.btn.btn-outline-secondary {
  border-color: var(--p-color-border-light);
  color: var(--p-color-text);

  &:hover {
    background-color: var(--p-color-text);
    color: var(--p-color-contrast-bg);
  }
}

.btn-light {
  color: var(--p-color-text);
  position: relative;
  background-color: var(--bs-tertiary-bg);
  border-color: var(--bs-tertiary-bg);

  &:hover{
    background-color: var(--bs-secondary-bg-subtle);
    border-color: var(--bs-secondary-border-subtle);
  }

  &:hover:not([disabled]) {
    color: var(--p-color-primary);
  }

  &:disabled {
    color: var(--p-color-disabled);
    background-color: var(--bs-tertiary-bg);
    border-color: var(--bs-tertiary-bg);
  }

  mat-spinner {
    position: absolute;
    left: -20px;
  }
}


.btn-icon {
  color: var(--p-color-text);
  background-color: transparent;
  border: none;
  border-radius: 5px;
  padding: 0.1em 0.3em;

  // color: var(--button-color);
  font-weight: 300;

  &:hover:not(:disabled) {
    color: var(--button-color);
    background-color: rgba(0,0,0,0.05);
  }

  &:disabled {
    color: var(--p-color-disabled);
    pointer-events: none;
  }
}

/*
 * BUTTON GROUP
 */
.btn-group {
  > .btn,
  > .btn-group > .btn {
    --bs-btn-active-border-color: rgba(0,0,0,0);
    border-width: 0;
    margin-bottom: 0; // Override default `<label>` value

    input[type="radio"],
    input[type="checkbox"] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }
  }

  &.btn-group-toggle {
    > .btn,
    > .btn-group > .btn {
      margin-bottom: 0; // Override default `<label>` value
  
      input[type="radio"],
      input[type="checkbox"] {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        pointer-events: none;
      }
    }
  }
}