:root {
    --topNav-height: 50px;
    
    --deselected-icon-color: var(--p-color-grey-400);
    --custom-topic-color: #D8FDE4;
    --filtered-term-weight-color: #a1aecd;

    --labeled-color: #34b536;
    --merged-color: #ee55ff;
    --unclear-color: #ff312d;
    --custom-topic-color: #00d9ff; // #55F0FF;
    --blank-color: #6A7883;

    // Desaturated versions of the colors:
    --labeled-suggested-color: #b9d6b4; // #72ac69;
    --merged-suggested-color: #e8bbec; // #d177d9;
    --unclear-suggested-color: #e9bab3; // #d37566;
    --custom-topic-suggested-color: #a9e1e2;

    --training-accuracy-color: #6cae75;
    --training-loss-color: #ff6745; // #ff7657;
    --validation-accuracy-color: #3dacac; // #f0aa57;
    --validation-loss-color: #ca8e00; // #f0aa57;
    --validation-background-color: #E3E0E3;//

    --light-text-color: #95989A;
    --block-unclear-color: #C95357;
    --candidate-selected: #F8F8F8;
    --unitVisibility-color: #35499D;
    --valid-requiered-color: #42A948;
    --invalid-required-color: #A94442;
    --anchor-background-color: #425CAB;
    --title-bar-background-color: #F9F9F9;
}

.ball-beat-color {
    color: var(--button-color) !important;
    width: auto !important;
}

.text-justify {
    text-align: justify;
}