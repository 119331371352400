// Dropdowns
//––––––––––––––––––––––––––––––––––––––––––––––––––

/* box back drop down */
.po-dropdown .dropdown-menu.show {
  border-radius: 0;
  width: 97%;
  background-color: var(--p-color-white);
  border: none;
  border-left: 2px solid var(--box-border-color);
  margin-left: -2px;
  -webkit-box-shadow: 6px 6px 14px -1px rgba(1, 48, 71, 0.07);
  -moz-box-shadow: 6px 6px 14px -1px rgba(0, 0, 0, 0.07);
  box-shadow: 6px 6px 14px -1px rgba(0, 0, 0, 0.07);
}

/* box button style on drop down */
.po-dropdown .dropdown-menu.show button {
  color: var(--light-grey-color);
  font-weight: 300;
  background-color: var(--p-color-white);

  &:hover {
    background-color: var(--pretty-color);
    color: var(--p-color-white);
  }
}

.modal-dropdown {
  height: 60px;
  border-radius: 2px;
  background-color: var(--p-color-white);
  box-shadow: 0 2px 4px 0 rgba(216, 216, 216, 0.5);

  .dropdown-toggle {
    height: 100%;
    width: 100%;
    border: 1px solid var(--light-lines-color);
    border-radius: 2px;
    background-color: var(--p-color-white);
    box-shadow: 0 2px 4px 0 rgba(216, 216, 216, 0.5);
  }

  .dropdown-menu.show {
    width: 100%;
  }
}

.po-dropdown .dropdown-menu {
  font-family: var(--p-font);
}

.po-dropdown .dropdown-toggle {
  border-width: 0 0 1px 0;
}

/* Carrot */
.dropdown-toggle::after {
  float: right;
  margin-top: .5em;
}

.dropdown-item {
  font-family: var(--p-font);
  font-weight: 300;

  &.active, &:focus {
    background: var(--button-color);
    color: var(--p-color-white);
    box-shadow: none;
    outline: none;
  }
}

.dropdown-item:last-child {
  margin-bottom: 0;
}

*[ngbDropdownMenu] {
  overflow: auto;
}
