.stack {
  > * + * {
    margin-top: 9px;
  }

  .stackItem {
    margin-top: 9px;
  }
}

.pGrid {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 12px;
  row-gap: 12px;
}

.pPanel {
  border: 1px solid var(--p-color-border-light);
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .pPanelHeader {
    font-weight: 400;
    font-size: 15px;
    padding: 6px 9px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    flex: none;

    &.primary {
      background-color: var(--p-color-primary);
      color: white;
      font-size: 14px;
    }

    &.secondary {
      background-color: var(--p-color-header-bg);
      border-bottom: 1px solid var(--p-color-border-light);
      font-family: var(--p-font-condensed);
      font-size: 13px;
      //text-transform: uppercase;
      //letter-spacing: -0.08px;
    }

    .backNav {
      float: left;
      cursor: pointer;
    }
  }

  .pPanelBody {
    padding: 9px;
    flex: auto;
    overflow: hidden;
    overflow-x: auto;
    overflow-y: hidden;
    background-color: var(--p-color-contrast-bg);
    font-size: 12px;
  }
}

.d-contents {
  display: contents !important;
}

.mat-drawer-container, .mat-drawer {
  --mat-sidenav-container-text-color: var(--p-color-text);
  --mat-sidenav-content-text-color: var(--p-color-text);
}
