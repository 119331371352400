/* You can add global styles to this file, and also import other style files */

// Bootstrap theme configuration
$primary: #274a9f;
$success: #274a9f;
$info: #274a9f;
$text-danger: #D0021B;
@import 'bootstrap/scss/bootstrap';

@import 'variables';

html, body {
  height: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  color: var(--p-color-text);
  font-family: var(--p-font);
  font-weight: 300;
  font-size: 15px;
  line-height: 1.45;
  width: 100%;
  background-color: var(--p-color-bg);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--p-font-header);
  font-weight: normal;
}

thead {
  background-color: rgba(111, 117, 130, 0.7);
  color: var(--p-color-white);
}

.table thead th { // .table opts in to using Bootstrap styling
  font-weight: 500;
}

.topnav-secondary {
  line-height: 50px;
  top: 50px;
  background-color: var(--sideNav-link-hover-selected-color);
  padding: 0 15px;
  //position: fixed;
  right: 0;
  width: 100%;
  margin: 0;
  z-index: 12;
  min-height: 50px;
  align-items: center;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  flex: none;

  &.with-menu-opened {
    width: calc(100% - 225px);
  }

  .link-title {
    padding: 9px 10px;
  }
}

@import 'analysis';
@import 'bootstrap-migration';
@import 'buttons';
@import 'core';
@import 'dropdowns';
@import 'explore';
@import 'forms';
@import 'ingest';
@import 'links';
@import 'material';
@import 'messages';
@import 'modals';
@import 'panels';
@import 'table';
@import 'toast';
@import 'tooltip';
