// @import "~jsoneditor/dist/jsoneditor.min.css";

.topNavSpacer {
    width: 100%;
    height: 50px;
    max-height: 50px;
    flex: none;
}

label {
    font-family: var(--p-font-header);
}

.emotionsLabel::first-letter {
    font-size: 150% !important;
    line-height: 0; // Don't affect the line height
    vertical-align: sub;
    letter-spacing: 0.15ch;
}
