// Links
//––––––––––––––––––––––––––––––––––––––––––––––––––

a {
  cursor: pointer;
  color: var(--p-color-link);
}

a.disabled {
  opacity: 0.2;
  pointer-events: none;
  cursor: not-allowed;
  color: var(--link-disabled-color);
}

a, a:link, a:visited, a:focus, a:active {
  color: var(--button-color);
  font-weight: 400;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: var(--p-color-link-hover);
}

