// Legacy Material Compatibility (used in Core)
mat-slide-toggle.mat-slide-toggle {

  $thumbWH: 10px;

  // not checked
  --slider-outline: 1px solid #6A7883; // var(--p-color-text);
  --slider-background: transparent;
  --slider-thumb-transform: translate3d(2px, 0, 0);
  --slider-thumb-background: #6A7883; // var(--p-color-text);

  &.mat-checked {
    --slider-outline: 1px solid #274a9f; // $p-color-primary;
    --slider-background: #274a9f; // $p-color-primary;
    --slider-thumb-transform: translate3d(22px, 0, 0);
    --slider-thumb-background: white;
  }

  .mat-slide-toggle-bar {
    border: var(--slider-outline) !important;
    background-color: var(--slider-background) !important;

    &:hover .mat-slide-toggle-persistent-ripple {
      opacity: 0.2;
    }
  }

  .mat-slide-toggle-thumb-container {
    width: $thumbWH;
    height: $thumbWH;
    top: 1px;
    transform: var(--slider-thumb-transform) !important;

    .mat-slide-toggle-thumb {
      width: $thumbWH;
      height: $thumbWH;
      box-shadow: none;
      background: var(--slider-thumb-background) !important;
    }

    .mat-ripple-element.mat-slide-toggle-persistent-ripple {
      transform: translate(-50%, 0);
    }
  }

}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent;
}

// Angular 16 Material compatibility ("mat-mdc-*" classes)
mat-slide-toggle.mat-mdc-slide-toggle {

  $thumbWH: 10px;

  .mdc-switch {
    --mdc-switch-handle-width: #{$thumbWH};
    --mdc-switch-handle-height: #{$thumbWH};
  }

  --mdc-switch-unselected-track-color: white;
  --mdc-switch-unselected-focus-track-color: white;
  --mdc-switch-unselected-pressed-track-color: white;

  --mdc-switch-unselected-handle-color: #6A7883;
  --mdc-switch-unselected-hover-handle-color: #274a9f;
  --mdc-switch-unselected-focus-handle-color: #274a9f;
  --mdc-switch-unselected-pressed-handle-color: #274a9f;
  
  &.mat-mdc-slide-toggle-checked {
    --mdc-switch-selected-track-color: #274a9f;

    --mdc-switch-selected-handle-color: white;
    --mdc-switch-selected-hover-handle-color: white;
    --mdc-switch-selected-focus-handle-color: white;
    --mdc-switch-selected-pressed-handle-color: white;
  }

  .mdc-switch__track {
    border: 1px solid #274a9f;
  }

  .mdc-switch__handle-track {
    left: 3px;
    width: calc(100% - var(--mdc-switch-handle-width) - 6px);

    .mdc-switch__handle {
      .mdc-switch__icons {
        display: none;
      }
    }
  }

}


.mat-mdc-autocomplete-panel {

  // Only applies to loading indicator if added
  mat-option.loading {
    position: sticky;
    top: -8px;
    min-height: unset;
    padding: 0;
    .mdc-list-item__primary-text {
      width: 100%;
    }
  }
}

.mat-drawer-inner-container::-webkit-scrollbar {
  display: none;
}