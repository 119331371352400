label {
  font-family: var(--p-font-header);
}

// Datatable -- Used by Ingest
//––––––––––––––––––––––––––––––––––––––––––––––––––
:root {
  --pagination-color: var(--link-color);
  --pagination-bg: var(--p-color-contrast-bg);
  --pagination-border-color: var(--p-color-border-light);
  --pagination-active-color: var(--p-color-bg);
  --pagination-active-bg: var(--p-color-link);
  --pagination-active-border-color: var(--pagination-active-bg);
  --pagination-disabled-color: var(--p-color-disabled);
  --pagination-disabled-bg: var(--p-color-contrast-bg);
  --pagination-disabled-border-color: var(--p-color-border-light);
}

.inlineEditable {
  text-decoration-line: underline;
  text-decoration-style: dashed;
  text-decoration-color: var(--p-color-primary);
}

mat-spinner.piensoDataGrid {
  margin: auto;
  margin-top: 100px;
}

$data-grid-cell-padding: 0.5em;
$data-grid-drag-dot-color: var(--p-color-text);

.ngx-datatable.bootstrap {
  @extend .table;
  font-family: var(--p-font-grid-data);
  font-size: 10px;
  font-weight: 400;
  padding: 0.3em;
  padding: 0px;
  border: 1px solid var(--p-color-border-light);
  margin: 0px;

  --bs-table-color-state: #{var(--p-color-text)};

  .datatable-header {
    @extend thead;
    color: var(--p-color-text) !important;
    background-color: transparent !important;

    .datatable-header-inner {
      @extend tr;

      .datatable-row-left {

        .datatable-header-cell {
          @extend th;
          border: none;
          //border-right: 1px solid var(--p-color-contrast-bg);
          height: 100%;
          background-color: var(--p-color-bg);
          padding: 0px;

          .datatable-header-cell-wrapper {
            padding: $data-grid-cell-padding;
            padding-top: $data-grid-cell-padding * 1.5;
          }
        }
      }

      .datatable-header-cell {
        @extend th;
        background-color: var(--p-color-bg);
        padding: 0px;
        padding-top: 0px;
        border: none;
        border-left: 1px solid var(--p-color-contrast-bg);
        border-right: 1px solid var(--p-color-contrast-bg);


        //&:first-child {
        //  border-left: none;
        //}

        .datatable-header-cell-wrapper {
          padding: $data-grid-cell-padding;
          padding-top: $data-grid-cell-padding * 1.5;

          .draggable {
            cursor: ew-resize;
          }

          .dragHandle {
            background: radial-gradient($data-grid-drag-dot-color 15%, transparent 16%) 0 0, radial-gradient($data-grid-drag-dot-color 15%, transparent 16%) 8px 8px;
            background-size: 4px 4px;
          }

          .resize-handle {
            cursor: col-resize;
          }

          .datatable-header-cell-label {
            font-size: 12px;
            &.inlineEditable {
              cursor: pointer;
            }
          }
          .typeDiv {
            position: relative;

            .typeSelectBox {
              margin-top: 0.5em;
            }
            mat-spinner {
              position: absolute;
              right: 0px;
              top: 0px;
            }
          }

          .datatable-icon-up {
            //@extend .fas;
            //@extend .fa-angle-up;
            @extend .align-text-top;
          }
          .datatable-icon-down {
            //@extend .fas;
            //@extend .fa-angle-down;
            @extend .align-text-bottom;
          }

        }




      }
    }
  }
  .datatable-body {
    @extend tbody;

    datatable-progress {
      .container {
        height: 5px;
        max-width: unset;
        padding-left: 0px;
        padding-right: 0px;

        .bar {
          @extend .progress-bar;
          @extend .progress-bar-striped;
          @extend .progress-bar-animated;
          height: 100%;
        }
      }
    }

    .datatable-body-row {
      @extend tr;

      .datatable-row-group {

        &:first-child {
          .datatable-body-cell {
            @extend td;
            border: none;
            padding: $data-grid-cell-padding;
            //border-right: 1px solid var(--p-color-grid-line);
            border-top: 1px solid var(--p-color-contrast-bg);
            border-bottom: 1px solid var(--p-color-contrast-bg);
            text-align: right;
            background-color: var(--p-color-bg);

            //&:first-child {
            //  border-top: none;
            //}

            .datatable-body-cell-label {
              right: 0px;
              padding-right: 0.5em;
            }
          }
        }

        .datatable-body-cell {
          @extend td;
          padding: $data-grid-cell-padding;
          border-top: none;
          border-right: 1px solid var(--p-color-grid-line);
          border-bottom: 1px solid var(--p-color-grid-line);
          background-color: var(--p-color-contrast-bg);
          vertical-align: center;
          position: relative;

          .datatable-body-cell-label {
            display: inline-block;
            position: absolute;
            top: 50%;
            -webkit-transform: translate(0, -50%);
            -moz-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            -o-transform: translate(0, -50%);
            transform: translate(0, -50%);
          }
        }
      }
    }
  }
  .datatable-footer {
    //@extend tfoot;
    @extend thead;
    background-color: var(--p-color-bg);
    color: var(--p-color-text);
    --bs-table-color-state: #{var(--p-color-text)};
    .datatable-footer-inner {
      .page-count {
        @extend .col;
      }
      .datatable-pager {
        @extend .col;
        ul {
          @extend .pagination;
          float: right;
          li {
            a {
              @extend .page-link;
              color: var(--pagination-color);
              background-color: var(--pagination-bg);
              border-color: var(--pagination-border-color);
              padding: .6em 1em .6em 1em;
              font-size: 10px;
              border-radius: 0;
              i {
                &.datatable-icon-prev {
                  //@extend .fas;
                  //@extend .fa-angle-double-left;
                }
                &.datatable-icon-left {
                  //@extend .fas;
                  //@extend .fa-angle-left;
                }
                &.datatable-icon-right {
                  //@extend .fas;
                  //@extend .fa-angle-right;
                }
                &.datatable-icon-skip {
                  //@extend .fas;
                  //@extend .fa-angle-double-right;

                  //&::before {
                  //  content: "\70";
                  //  text-shadow: 3px 0px, -3px 0px;
                  //}
                }
              }
            }
            &.active {
              a {
                z-index: 2;
                color: var(--pagination-active-color);
                background-color: var(--pagination-active-bg);
                border-color: var(--pagination-active-border-color);
              }
            }
            &.disabled {
              a {
                color: var(--pagination-disabled-color);
                pointer-events: none;
                background-color: var(--pagination-disabled-bg);
                border-color: var(--pagination-disabled-border-color);
              }
            }
            &:first-child {
              a {
                margin-left: 0;
                //@include border-left-radius($border-radius);
              }
            }
            &:last-child {
              a {
                //@include border-right-radius($border-radius);
              }
            }
          }
        }
      }
    }
  }
  //&.table-striped {
  //  @extend .table-striped;
  //  .datatable-body {
  //    .datatable-body-row::nth-of-type(odd) {
  //      background-color: $table-accent-bg;
  //    }
  //  }
  //}
  //&.table-hover {
  //  @extend .table-hover;
  //  .datatable-body {
  //    .datatable-body-row {
  //      @include hover {
  //        background-color: $table-hover-bg;
  //      }
  //    }
  //  }
  //}
  //&.table-bordered {
  //  @extend .table-bordered;
  //}
}

.ngx-datatable .datatable-header .datatable-header-inner {
  padding: 0 !important
}

.ngx-datatable .datatable-body datatable-selection {
  padding: 0 !important;
  background-color: transparent;
}