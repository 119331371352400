/* HOTFIXES AFTER UPGRADING TO BOOTSTRAP 5 */

.modal-content {
  --bs-modal-bg: #FFF !important
}

.progress-bar, .ngx-datatable.bootstrap .datatable-body datatable-progress .container .bar {
  --bs-progress-bar-bg: var(--bs-primary) !important;
}

.dropdown-menu {
  --bs-dropdown-bg: #FFF;

  .dropdown-item {
    color: var(--bs-secondary-text-emphasis);
    
    &:hover {
      color: var(--bs-secondary-text-emphasis);
      background-color: var(--bs-secondary-bg);
    }
  }
}



