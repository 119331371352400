$p-gridList-row-height: 62px;

.gridList {
  width: 100%;
  display: grid;
  position: relative;
  //top: 61px;
  flex: auto;
  overflow-y: auto;

  grid-template-rows: 3.5rem; // header
  grid-auto-rows: $p-gridList-row-height; // body

  grid-template-columns: [start] 1fr [end]; // Override this, but keep [start] and [end]

  justify-items: left;
  column-gap: 20px;
  justify-content: space-between;

  cdk-header-row{
    position: sticky;
    top: 0;  
    z-index: 10;
  }

  cdk-header-row, cdk-row {
    display: grid;
    grid-template-columns: subgrid;
    grid-column: start / end;

    cdk-header-cell, cdk-cell {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.no-overflow {
        text-overflow: unset;
      }

      &:first-child {
        padding-left: 3rem;
        font-weight: 500;
      }

      &:not(:last-child) {
        padding-right: 1em;
      }

      &:last-child {
        padding-right: 3rem;
      }
    }
  }

  cdk-header-row {
    background-color: var(--p-color-header-bg);
  }

  cdk-row {
    background-color: var(--p-color-contrast-bg);
    border-bottom: 1px solid var(--p-color-border-light);
  }

  cdk-header-cell {
    //border-top: 0.5px solid var(--p-color-border-light);
    border-bottom: 0.5px solid var(--p-color-border-light);
    //box-shadow: inset 0 -1px 3px 0 rgba(194, 197, 198, 0.44);

    height: 3.5rem !important;
    background-color: var(--p-color-header-bg);
    font-size: 15px;
    z-index: 11;

    vertical-align: bottom;
    align-self: end;
    padding: 0.75rem 0 0.75rem 0;
    text-align: left;

    color: var(--p-color-text);
    font-family: var(--p-font-condensed);
    font-weight: 500;
    //letter-spacing: -0.07px;
    line-height: 18px;
    text-transform: uppercase;

    .mat-sort-header-container {
      vertical-align: middle;
      height: 100%;
    }
  }

  cdk-cell {
    background-color: var(--p-color-contrast-bg);
    font-size: 13px;

    line-height: $p-gridList-row-height;
    height: $p-gridList-row-height !important;
    //padding: 0.75rem 0 0.75rem 0;
    border-bottom: 1px solid var(--p-color-border-light);

    vertical-align: middle;
    //line-height: 2.3rem;
    //min-height: 40px;

    a {
      font-size: 15px;
    }

    .action-link {
      color: var(--p-color-link);
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: -0.09px;
      line-height: 18px;
      font-family: var(--p-font-condensed);
      font-size: 15px;

      &:hover {
        color: var(--p-color-link-hover);
      }

      &.failed {
        color: var(--p-color-error) !important;
      }

      &.fatal {
        color: var(--p-color-error) !important;
      }
    }

    .btn {
      padding-bottom: 0;
      padding-top: 0;
      box-shadow: none !important;

      &:focus {
        box-shadow: none !important;
      }

      &.downloadBtn {
        padding: 0;
        margin: 0 15px;
      }
    }
  }
}

$font-size: 12px;

@mixin tableGridHeaderCell {
  border-bottom: 0.5px solid var(--p-color-border-light);

  background-color: var(--p-color-header-bg);
  z-index: 11;

  color: var(--p-color-text);
  font-family: var(--p-font-condensed);
  font-weight: 500;

  font-size: $font-size;
  padding: 6px 9px;

  position: sticky;
  top: 0;

  white-space: nowrap;

  &:first-child {
    padding-left: 9px;
  }

  &:last-child {
    padding-right: 9px;
  }
}

// Used in tables
.tableGrid {
  $row-height: 40px;
  height: 100%;
  width: 100%;
  display: grid;
  position: relative;
  grid-template-columns: max-content; // Should be overridden by instantiator!
  grid-template-rows: max-content;
  grid-auto-rows: max-content;
  align-items: center;
  overflow-y: auto;

  cdk-header-row, cdk-row {
    display: contents;
  }

  cdk-header-cell {
    @include tableGridHeaderCell;
  }

  cdk-row {
    cdk-cell {
      font-size: $font-size;
      padding-left: 9px;
      padding-right: 9px;

      line-height: unset;
      // height: $row-height;
      min-height: $row-height;
      border: none;
      align-self: stretch;
  
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;

      border-bottom: 1px solid var(--p-color-grid-line);

      > * {
        display: block;
      }
  
      &:first-child {
        padding-left: 9px;
      }
    }

  }
}

.tableWrapper {
  display: flex;
  flex: auto;
  overflow: hidden;
  flex-direction: column;
}

.numberCell {
  text-align: right;
  font-variant-numeric: tabular-nums;
}

.control-bar {
  position: sticky;
  flex: none;
}

.paginator-visible {
}

mat-paginator {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 -2px 4px #cacaca80;
}

.datatable-load-blocker { // Only used in Core
  cursor: progress;
  cdk-table {
    pointer-events: none;
  }
}