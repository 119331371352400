:root {
    // Typography
    --p-font: Barlow, "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif;
    --p-font-condensed: "Barlow Condensed", "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif;
    --p-font-mono: "Roboto Mono", "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif;
    --p-font-header: var(--p-font-condensed);
    --p-font-grid-data: var(--p-font-mono);
    
    --p-color-blue: #274a9f;
    --p-color-green: #14803f;
    --p-color-orange: #f25a2c;
    --p-color-red: #D05555;
    --p-color-yellow: #FFBA00;
  
    --p-color-white: #FFFFFF;
    --p-color-grey-100: #F6F9FA;
    --p-color-grey-200: #F5F5F5;
    --p-color-grey-300: #f0f0f0;
    --p-color-grey-400: #EEF0F1;
    --p-color-grey-500: #E3E0E3;
    --p-color-grey-600: #D8DADC;
    --p-color-grey-700: #D2D2D2;
    --p-color-grey-800: #c7c7c7;
    --p-color-grey-850: #9b9b9b;
    --p-color-grey-900: #6A7883;
    --p-color-grey-1000: #4a4a4a;
    --p-color-grey-1100: #2c2c2c;
    --p-color-grey-1200: #1f1f1f;
  
    --light-grey-color: var(--p-color-grey-900);
    --grey-color: #8C8C8C;
  
    --p-color-primary: var(--p-color-blue);
    --p-color-accent1: var(--p-color-blue);
    --p-color-accent2: #bf1363;
  
    --p-color-primary-darker: #1f3c80;
  
    --p-color-success-green: #64A97B;
    --p-color-error: darkred;
  
    --p-color-text: var(--p-color-grey-900);
    --p-color-bg: var(--p-color-grey-100);
    --p-color-header-bg: var(--p-color-grey-400);
    --p-color-contrast-bg: var(--p-color-white);
    --p-color-tinted-bg: #D6DBEA; // #E9FBF2;
  
    --p-color-border-light: var(--p-color-grey-500);
    --p-color-line-light: var(--p-color-border-light);
    --p-color-line-dark: var(--p-color-grey-800);
    --light-lines-color: var(--p-color-grey-500);
    --dark-lines-color: var(--p-color-grey-800);
    --p-color-grid-line: var(--p-color-grey-300);
    --p-color-table-border: #EEEEEE;
    --table-border-color: #EEEEEE;
    --form-border-color: #E9E9E9;
  
    --p-color-value-positive: #41AEF9;
    --p-color-value-negative: var(--p-color-accent2);
    
    --box-shadow-color: rgba(142, 142, 142, 0.5);
    --p-shadow-color: #5E556B;
  
    --p-color-disabled: #D8DADC;
  
    --link-color: var(--p-color-primary); // #41AEF9
    --link-disabled-color: #B6B6B6;
    --p-color-link: var(--link-color);
    --p-color-link-hover: #182d62;
    --p-color-link-hover-bg: var(--p-color-tinted-bg);
  
    --topNav-link-color: var(--p-color-grey-900);
    --topNav-selected-link-color: var(--p-color-blue);
    --sideNav-link-color: var(--p-color-grey-400);
    --sideNav-link-hover-selected-color: var(--p-color-grey-400);
  
    --button-color: var(--p-color-blue);
    --button-primary-hover-color: #1F3B7F;
    --button-secondary-hover-color: var(--p-color-grey-850);
    --btn-color: white;
    --btn-action-disabled-color: #676979;
    --btn-border-color: #333333;
    --btn-gray-color: #A8A8A8;
    --btn-secondary-border-color: #D2D2D2;
  
    --p-color-bookmark: #FFB850;
    
    --p-color-input-placeholder: var(--p-color-grey-800);
    --input-placeholder-color: var(--p-color-grey-800);
    --input-error-color: #D0021B;
    --input-error-background-color: #D0021B11;
  
    --p-color-table-selection-background: var(--p-color-grey-400);
  
    --p-color-white-opacity: rgba(255,255,255,0.33);
    --loader-color: #68A97B;
    --box-border-color: var(--p-color-grey-200);
    --pretty-color: #00D0BE;
    --tooltip-background-color: #EEF0F1;
  
    // Bootstrap theme configuration (This should be moved to a fuller theme when ready)
    // --body-bg: var(--p-color-bg);
    // --body-color: var(--p-color-text);
}
